import React from "react";
import styled from "styled-components";

import Skepta from "./wrappers/Skepta";
import {devices} from "./devices";

/* Image imports */
import Logo from "./../images/turkbox-logo.png";

const Footer = () => {
    return (
        <FooterSection>
            <Skepta>
                <MenuFlex>
                    <MenuColumn>
                        <b>Resources</b>
                        <MenuColumnOption href={`/about`}>About</MenuColumnOption>
                        <MenuColumnOption href={`/blog`}>Blog</MenuColumnOption>
                        <MenuColumnOption href={`/pilot`}>Join Pilot</MenuColumnOption>
                    </MenuColumn>
                    <MenuColumn>
                        <b>External</b>
                        <MenuColumnOption target="_blank" rel="noopener noreferrer"  href={`https://angel.co/company/turkbox/jobs`}>Careers</MenuColumnOption>
                        <MenuColumnOption target="_blank" rel="noopener noreferrer" href={`https://www.notion.so/Turkbox-Icon-Attributions-0ea68bfdad094f0e905b85cd3323aa1a`}>Attributions</MenuColumnOption>
                    </MenuColumn>
                    <MenuColumn>
                        <b>Contact</b>
                        <MenuColumnOption>team@turkbox.co</MenuColumnOption>
                        <MenuColumnOption target="_blank" rel="noopener noreferrer"  href={`https://twitter.com/turkboxio`}>Twitter</MenuColumnOption>
                    </MenuColumn>
                    <MenuColumn>
                        <LogoContainer>
                            <LogoSpan role="img" aria-label="Turkbox Logo"/>
                        </LogoContainer>
                    </MenuColumn>
                </MenuFlex>
                <p align="right">© 2020 Turkbox Technologies Pvt Ltd.</p>
            </Skepta>
        </FooterSection>
    )
}

const FooterSection = styled.section`
    height: 200px;
    width: 100%;
    padding: 45px 0;
    p {
        display: block;
        font-size: 0.9rem;
        color: #4c4c4c;
        font-weight: 400;
    }
`

const MenuFlex = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`

const MenuColumn = styled.div`
    height: 100%;
    width: 45%;
    vertical-align: top;
    display: block;
    margin-bottom: 15px;

    b {
        font-size: 1rem;
        color: #000;
        font-weight: 400;
    }

    p {
        margin: 10px 0 20px 0;
    }

    @media ${devices.tablet} {
        width: 20%;
        display: inline-block; 
        margin-bottom: 0;
    }
`

const MenuColumnOption = styled.a`
    display: block;
    font-size: 0.9rem;
    color: #4c4c4c;
    font-weight: 400;
    margin: 10px 0 2px 0;
    text-decoration: none;

    &:hover {
        color: #6c6a6a;
    }
`

const LogoContainer = styled.div`
    height: 100%;
    width: 100%;
    text-align: right;
`;

const LogoSpan = styled.span`
    width: 100%;
    height: 40px;
    background: url('${Logo}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: none;
    

    @media ${devices.tablet} {
  
        display: block;
    }
    
`;

export default Footer;