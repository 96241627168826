import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import {Link} from "gatsby";

/* Local Component imports */
import Skepta from "./../wrappers/Skepta"
import {ExternalHeroButton, NavigationButton} from "./../Buttons";

/* Image imports */
import Logo from "./../../images/turkbox-logo.png";

import HamburgerIcon from "./../../images/icons/Hamburger.png";
import CrossIcon from "./../../images/icons/Cross.png";
import {devices} from "./../devices";

const MobileNavigation = () => {

    const [menuVisible, showMenu] = useState(false);

    useEffect(() => {
        showMenu(false);
    }, []);

    return (
        <React.Fragment>
        <NavigationSection>
            <Skepta width={`90%`} height="100%" display="flex" justifyContent="space-between" alignItems="center">
                <Link to="/">
                    <LogoContainer>
                        <LogoSpan role="img" aria-label="Turkbox Logo"/>
                    </LogoContainer>
                </Link>
                <MenuIcon onClick={() => showMenu(!menuVisible)} variant={(menuVisible ? CrossIcon : HamburgerIcon)}>
                    <span className="icon" role="button"></span>
                </MenuIcon>
            </Skepta>
        </NavigationSection>
        <MenuExpanse position={(menuVisible ? `normal` : `top-hidden`)}>
            <Skepta width={`90%`}>
                <NavigationButton role="button" to="/about">About</NavigationButton>
                <NavigationButton role="button" to="/blog">Blog</NavigationButton>
                <NavigationButton role="button" to="/pilot">Join Pilot</NavigationButton>
                <ExternalHeroButton role="button" href="https://dashboard.turkbox.io">Login</ExternalHeroButton>
            </Skepta>
        </MenuExpanse>
        </React.Fragment>
    )
}

/*
*/

const NavigationSection = styled.section`

    @media ${devices.tablet} {
        display: none;
    }

    width: 100%;
    height: 70px;
    position: fixed;
    top: 0px;
    background: #fff;
    transition: background 120ms ease-in 0s, top 240ms ease-in 0s, box-shadow 240ms ease-in 0s;
    box-shadow: 0 3px 9px -3px rgba(88,88,88,.2);
    z-index: 100;

`;

const MenuIcon = styled.div`
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #ececec;
    vertical-align: top;
    margin: 0;
    display: inline-block;
    position: relative;
    padding: 0;

    span.icon {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('${props => props.variant}');
        background-position: center;
        background-size: 45%;
        background-repeat: no-repeat;
    }

    &:hover {
        border-color: #2aa474;
        cursor: pointer;
    }
`

const LogoContainer = styled.div`
    height: 65px;
    width: 140px;
`;

const LogoSpan = styled.span`
    width: 100%;
    height: 100%;
    display: block;
    background: url('${Logo}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const MenuExpanse = styled.div`

    @media ${devices.tablet} {
        display: none;
    }

    width: 100%;
    position: fixed;
    background-color: #fff;
    top: ${props => props.position === `top-hidden` ? `-200px` : `65px`};
    transition: top 200ms ease-in 0s;
    margin-top: 5px;
    z-index: 90;
    padding: 20px 0;
    box-shadow: 0 3px 9px -3px rgba(88,88,88,.2);
    
    a {
        width: 100%;
        display: block;
    }
`


export default MobileNavigation;