import React from "react";
import styled from "styled-components";

import {Link} from "gatsby";

import {HeadingOne, HeadingTwo} from "./../Headings";
import Skepta from "../wrappers/Skepta";

import {devices} from "./../devices";
import ArticleMetadataTab from "./ArticleMetadataTab";


import PilotWithKLThumbnail from "./../../images/blog/pilot-with-kl/cover.jpg";
import BuildingWithTurkboxThumbnail from "./../../images/thumbnails/building-turkbox.png"

const Feed = () => {
    return (
        <FeedContainer>
            <Skepta>
                <HeadingOne>Blog</HeadingOne>
                <BlogPostFeed>
                    <BlogPostFeedItem>
                        <Link to="/blog/pilot-with-kl" className="thumbnail">
                            <BlogPostFeedItemThumbnail background={PilotWithKLThumbnail}/>
                        </Link>
                        <BlogPostFeedItemContent>
                            <Link to="/blog/pilot-with-kl">
                                <HeadingTwo>Notes from Phase I of our pilot with Khabar Lahariya</HeadingTwo>
                            </Link>
                            <ArticleMetadataTab authorName={`Tanay Vardhan`} date={`June 15, 2020`} length={`5 min`}/>
                            <p className={`blurb`}>Updates and insights from our work with Khabar Lahariya for the past year</p>
                        </BlogPostFeedItemContent>
                    </BlogPostFeedItem>
                    <BlogPostFeedItem>
                        <Link to="/blog/building-turkbox" className="thumbnail">
                            <BlogPostFeedItemThumbnail background={BuildingWithTurkboxThumbnail}/>
                        </Link>
                        <BlogPostFeedItemContent>
                            <Link to="/blog/building-turkbox">
                                <HeadingTwo>Building Turkbox: A way to pay for news by training AI</HeadingTwo>
                            </Link>
                            <ArticleMetadataTab authorName={`Tanay Vardhan`} date={`May 9, 2019`} length={`4 min`}/>
                            <p className={`blurb`}>Some notes from our journey over the past couple of months and a roadmap for what lies ahead</p>
                        </BlogPostFeedItemContent>
                        
                    </BlogPostFeedItem>
                </BlogPostFeed>
            </Skepta>
        </FeedContainer>
    )
}

const FeedContainer = styled.section`
    min-height: 100%;
    width: 100%;
    background-color: #F4F4F0;
    padding: 90px 0 7.5% 0;
    text-align: center;

    @media ${devices.tablet} {
        padding: 10% 0 7.5% 0;
    }
`

const BlogPostFeed = styled.div`
    margin: 40px 0;
`

const BlogPostFeedItem = styled.div`
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 3px 9px -3px rgba(88,88,88,.2);
    background-color: #fff;
    margin-bottom: 60px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    a {
        color: initial;
        text-decoration: none;
    }

    a.thumbnail {
        width: 100%;
        height: 250px;
        display: block;
    }

    @media ${devices.tablet} {

        height: 300px;

        a.thumbnail {
            width: 50%;
            height: 100%;
        }

        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 35px;
        
    }

`

const BlogPostFeedItemThumbnail = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: url('${props => props.background}');
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    transition: filter 320ms ease-in 0s;
    &:hover {
        cursor: pointer;
        filter: grayscale(0%);
    }
    display: block;

    
`

const BlogPostFeedItemContent = styled.div`
    width: 100%;
    padding: 15px;

    p.blurb {
        margin-top: 25px;
    }

    @media ${devices.tablet} {
        width: 50%;
        height: 100%;
        padding: 30px;
    }
`

export default Feed;