import styled from "styled-components";

const HeadingOne = styled.h1`
    font-size: ${props => props.size || `1.8rem`};
    line-height: ${props => props.lineHeight || `1.8rem`};
    font-family: 'Rufina', 'Georgia', sans-serif;
    font-weight: 700;
    text-align: ${props => props.align || `initial`};
    margin: ${props => props.margin};
`

const HeadingTwo = styled.h2`
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: 'Rufina', 'Georgia', sans-serif;
    font-weight: 700;
    margin: ${props => props.margin};
    text-align: ${props => props.align || `initial`};
`

const SoftUppercase = styled.h2`
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
    color: #979696;
    margin: ${props => props.margin};
`

export {
    HeadingOne,
    HeadingTwo,
    SoftUppercase
}