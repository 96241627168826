import styled from "styled-components";

import {devices} from "./../devices";

const Skepta = styled.div`
    width: 90%;
    margin: 0 auto;
    height: ${props => props.height || `initial`};
    display: ${props => props.display || `block`};
    justify-content: ${props => props.justifyContent || `initial`};
    align-items: ${props => props.alignItems || `initial`}; 

    @media ${devices.tablet} {
        width: ${props => props.width || `70%`};
    }

    @media ${devices.desktopL} {
        width: 45%;
    }
`

export default Skepta;