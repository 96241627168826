import React from "react" 

// Section Component Imports
import Navigation from "../components/navigation/Navigation";
import Footer from "../components/Footer";
import Feed from "../components/blog/Feed";

import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Blog"/>
    <Navigation/>
    <Feed/>
    <Footer/>
  </>
  
)

export default IndexPage
