import React from "react";
import styled from "styled-components";

// Load author images
import authorImageTanayV from "./../../images/blog/authors/tanayv.jpeg";


const ArticleMetadataTab = ({authorName, date, length}) => {
    return (
        <ArticleInformationTab>
            <AuthorPicture src={authorImageMap[authorName]} role={`img`} alt={`Display picture for ${authorName}`}/>
            <ArticleMetadata>
                <p>{authorName}</p>
                <p className="secondary">{date} · {length} read</p>
            </ArticleMetadata>
        </ArticleInformationTab>
    )
}

const ArticleInformationTab = styled.div`
    width: 100%;
    height: 45px;
`

const AuthorPicture = styled.span`
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
    vertical-align: top;
    background: url('${props => props.src}');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`

const ArticleMetadata = styled.div`
    display: inline-block;
    vertical-align: top;
    p {
        margin: 0;
        padding: 0px 10px;
        margin: 0;
    }

    p.secondary {
        font-size: 0.8rem;
        color: #4c4c4c;
        padding: 0px 10px;
        margin: 0;
        line-height: 1rem;
    }
`

const authorImageMap = {
    "Tanay Vardhan": authorImageTanayV
};

export default ArticleMetadataTab;