import styled from "styled-components";
import {Link} from "gatsby";


// Import icons for buttons
import ChevronSideIcon from "./../images/icons/ChevronSide.svg";

const HeroButton = styled(Link)`
    color: #fff;
    text-decoration: none;
    background: #4DC193;
    padding: 7.5px 12px;
    outline: none;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px;
    user-select: none;
    transition: background 120ms ease-in 0s;
    cursor: pointer;
    &:hover {
        background: #43a980;
    }
`

const NavigationButton = styled(Link)`
    color: #000;
    background: none;
    text-decoration: none;
    padding: 7.5px 12px;
    outline: none;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    user-select: none;
    transition: background 120ms ease-in 0s;
    cursor: pointer;
    &:hover {
        background: rgba(76, 74, 66, 0.08);
    }
`;

const ArrowButton = styled(Link)`
    color: #4DC193;
    background: url('${ChevronSideIcon}');
    background-size: 10px;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 7.5px 24px 7.5px 0;
    outline: none;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    user-select: none;
    transition: color 120ms ease-in 0s, padding 120ms ease-in 0s, background-size 120ms ease-in 0s;
    cursor: pointer;
    &:hover {
        color: #009265;
        padding: 7.5px 30px 7.5px 0;
        background-size: 12px;
    }
    text-decoration: none;
`

const ExternalLinkButton = styled.a`
    color: #4DC193 !important;
    background: url('${ChevronSideIcon}');
    background-size: 10px;
    background-position: center right;
    background-repeat: no-repeat;
    padding: 7.5px 24px 7.5px 0;
    outline: none;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    user-select: none;
    transition: color 120ms ease-in 0s, padding 120ms ease-in 0s, background-size 120ms ease-in 0s;
    cursor: pointer;
    &:hover {
        color: #009265;
        padding: 7.5px 30px 7.5px 0;
        background-size: 12px;
    }
    text-decoration: none;
`

const ExternalHeroButton = styled.a`
    color: #fff;
    text-decoration: none;
    background: #4DC193;
    padding: 7.5px 12px;
    outline: none;
    border-radius: 3px;
    box-shadow: none;
    border: none;
    box-shadow: rgba(15, 15, 15, 0.1) 0px 1px 2px;
    user-select: none;
    transition: background 120ms ease-in 0s;
    cursor: pointer;
    &:hover {
        background: #43a980;
    }
`

export {
    HeroButton,
    NavigationButton,
    ArrowButton,
    ExternalLinkButton,
    ExternalHeroButton
}