import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import {Link} from "gatsby";

/* Local Component imports */
import Skepta from "./../wrappers/Skepta"
import {ExternalHeroButton, NavigationButton} from "./../Buttons";

/* Image imports */
import Logo from "./../../images/turkbox-logo.png";

import {devices} from "./../devices";
import MobileNavigation from "./MobileNavigation";


const Navigation = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <React.Fragment>
        <NavigationSection  scroll={scrollPosition} >
            <Skepta height="100%" display="flex" justifyContent="space-between">
                <Link to="/">
                    <LogoContainer>
                        <LogoSpan role="img" aria-label="Turkbox Logo"/>
                    </LogoContainer>
                </Link>
                <MenuBar>
                    <NavigationButton role="button" to="/about">About</NavigationButton>
                    <NavigationButton role="button" to="/blog">Blog</NavigationButton>
                    <NavigationButton role="button" to="/pilot">Join Pilot</NavigationButton>
                    <ExternalHeroButton role="button" href="https://dashboard.turkbox.io">Login</ExternalHeroButton>
                </MenuBar>
            </Skepta>
        </NavigationSection>
        <MobileNavigation/>
        </React.Fragment>
    )
}

const NavigationSection = styled.section`


    display: none;

    @media ${devices.tablet} {
        width: 100%;
        height: 70px;
        position: fixed;
        top: ${props => props.scroll !== 0 ? `0px` : `15px`};
        background: ${props => props.scroll !== 0 ? `#fff` : `none`};
        transition: background 120ms ease-in 0s, top 240ms ease-in 0s, box-shadow 240ms ease-in 0s;
        box-shadow: ${props => props.scroll !== 0 ? `0 3px 9px -3px rgba(88,88,88,.2)` : `none`};
        z-index: 100;
        display: block;
    }
    
`;

const LogoContainer = styled.div`
    height: 100%;
    width: 140px;
`;

const LogoSpan = styled.span`
    width: 100%;
    height: 100%;
    display: block;
    background: url('${Logo}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;


/* Styling for Menu Bar */
const MenuBar = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    a {
        margin: 0 10px;
        &:last-of-type {
            margin: 0 0 0 10px;
        }
    }
`;

export default Navigation;